import logo from './logo.svg';
import './App.css';
import LeaderBoard from './Pages/LeaderBoard/LeaderBoard';
import { Routes, Route, useNavigate} from "react-router-dom";
import { Provider } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, } from 'react';
import TYPES from "../src/redux/types"
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

 // const totalUsers = useSelector(state => state.users)
  
 // const totalUsers2 = useSelector(state => state.users)

 // const totalUsers3 = useSelector(state => state.leaderBoardDetail)

  const dispatch = useDispatch()

  useEffect(() => {
  
    dispatch({ type: TYPES.GET_USERS })

    dispatch({ type: TYPES.GET_LEADER_BOARD })




    axios.get(`https://mint.infinityvoid.io/gameApi/gameUser`)
    .then((response) => {
   //   console.log("%%%%%%%%%%%")
   //   console.log(response.data.rows)
  })





  }, [dispatch,]);



  const navigate = useNavigate();
useEffect(()=>{
  navigate('/quiz');
})





  return (

    <div >
  <Routes>

  <Route path="/quiz" element={ <LeaderBoard/>} />


  </Routes>
             
      
    </div>
  );
}

export default App;
