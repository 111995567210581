import React, { useEffect,useState } from 'react';

import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import img1 from "../../imgs/avatar-png-image.jpg"
import Pagination from "../../Components/Pagination/Pagination.jsx"
import "./leaderBoard.css"
import TYPES from "../../redux/types"  



function LeaderBoard() {


    const totalLeaderBoard = useSelector(state => state.leaderBoardSlice)
    
    
  //console.log(totalLeaderBoard)


    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);



  
    const pages = [];
    /*  data.length */
for (let i = 1; i <= Math.ceil(totalLeaderBoard.length / itemsPerPage); i++) {
pages.push(i);
}


const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};



const indexOfLastItem = currentPagess * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = totalLeaderBoard.slice(indexOfFirstItem, indexOfLastItem);


const renderPageNumbers = pages.map((number) => {
if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
return (
<li

key={number}
id={number}
onClick={handleClick}

className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
>
{number}
</li>
);
} else {
return null;
}
});


const handleNextbtn = () => {
setcurrentPagess(currentPagess + 1);

console.log("click next")

if (currentPagess + 1 > maxPageNumberLimit) {
setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
}
};

const handlePrevbtn = () => {
setcurrentPagess(currentPagess - 1);

if ((currentPagess - 1) % pageNumberLimit == 0) {
setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
}
};

let pageIncrementBtn = null;
if (pages.length > maxPageNumberLimit) {
pageIncrementBtn = <li  className='pageNumberList '   onClick={handleNextbtn}> &hellip; </li>;
}

let pageDecrementBtn = null;
if (minPageNumberLimit >= 1) {
pageDecrementBtn = <li  className='pageNumberList '     onClick={handlePrevbtn}> &hellip; </li>;
}



//console.log(totalLeaderBoard)

     
  //  console.log(totalUsers)
    
  //  console.log(totalLeaderBoard )


    


 //console.log(totalLeaderBoard.length)



 const [loading, setLoading] = useState(true);


//console.log(totalLeaderBoard)

    useEffect(()=>{
    
      setLoading(true)

         if(totalLeaderBoard.length>0){
     setTimeout(()=>{
      setLoading(false)
     },6000)
         
        }
  

    }, [])




    return (<>

         <div  style={{ width:"100%", height:"100vh", backgroundColor: "#000",}}>
{/*
         {loading == true ? (
        <div className="spinner-container" 
        style={{border:"2px solid red",width:"100%",height:"100vh", margin:"0px", paddingTop:"35px",overflow:"hidden"}}
        >
          <Spinner animation="border" variant="primary" style={{color:"red"}} />
        </div>
         */}
         
         {loading ? (
       
<div style={{
  width: "100%",
  height: "100vh",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}}>
  <div style={{
    width: "100%",
    position: "absolute",
    textAlign: "center",
  }}>
   <h3 style={{color:"white"}}> Loading... </h3> 
    </div>
</div>

    ) : (<>



         <br/>
              <Container  style={{ width:"100%", }}>
            
             
      <div class="table-container">

<table  className="non-responsive-table"   >
<thead 
              className='table-pink'
              style={{backgroundColor:"#26F8FF"}} >
                  <tr>
                  <th   style={{textAlign:"center",}} >Rank</th>
                    <th    style={{textAlign:"center"}} >Username</th>
                    <th  style={{textAlign:"center"}} >Country</th> 
                    <th style={{textAlign:"center"}}> Score</th>
           
                  </tr>
                </thead>
  <tbody>
  
  {/*                    
  {totalLeaderBoard  && totalLeaderBoard.map((item, index) => {
  */}

 
{currentItems && currentItems.map((item, index) => {

return ( 


    <tr   >                                  
                
      <td>
       <div style={{ padding:"8px",textAlign:"center",color:"#FC92F1" }} >
       {  (currentPagess -1) * itemsPerPage + (index + 1) }
         </div>
      </td> 
          
   
      <td  >
      <div  style={{width:"100%", padding:"8px" ,textAlign:"center",color:"#FC92F1",}} >          
      {item.userName}
        </div>
      </td>
      
            

      <td > 
           <div style={{ padding:"8px",textAlign:"center",color:"#FC92F1" }}>
           
            {/*  {item.besttime}  */}
   
              </div>      
        </td>
    
     
      <td > 
           <div style={{padding:"8px",textAlign:"center",color:"#FC92F1" }}> {item.score} </div>      
        </td>
      
      


    </tr>
     
 
  );
    }    )}
  

</tbody>

</table>

</div>





            </Container>
       


            <div   style={{width:"100%", position:"fixed", bottom:"0px"}}>      
          <Pagination

         
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />

</div>


</>)}

            </div>
    </>);
}

export default LeaderBoard;