




import axios from 'axios'

   axios.defaults.baseURL = 'https://mint.infinityvoid.io/gameApi'

 //  axios.defaults.baseURL = 'http://localhost:9081/api'







export const getUsersAPI =  async () => axios.get('/gameUser')


export const getUserByIdAPI = async (id) => {
    console.log(id)
    const response = await axios.get(`/gameUser/${id}`)
    console.log(response)
    return response.data;
}

export const getUserByWalletAPI = async (walletAddress) => {
   
 
    console.log(walletAddress)
    

    const response = await axios.get(`/gameUser/byWallet/${walletAddress}`);

    return response.data;
}

export const createUserAPI = async (user) => {

    axios.post(`/gameUser`, user)
}


export const updateUserAPI = async (id,data) => {
 
    axios.put(`/gameUser/${id}`, data)
}




export const getUserSearchAPI =  async (data) => {

    console.log("...........")

      console.log(data)

    const response = await axios.get(`/gameUser?filter[name]?q=${data.searchName}&filter[walletAddress]?q=${data.ownerAddress}`);
   
   
    return response.data


}








export const getLeaderBoardAPI =  async () => axios.get('https://mint.infinityvoid.io/gameApi/leaderboardQuiz')




