import { createSlice } from "@reduxjs/toolkit"

const leaderBoard = createSlice({
    name: 'leaderBoard',
    initialState: {
       
       
            walletAddress: '',
            email: '',
        
    },


    reducers: {
 

        getleaderBoardSlice: (state, action) => {
            state = action.payload
            
            return state
        },

    }
})

export const { getleaderBoardSlice } = leaderBoard.actions

export default leaderBoard.reducer