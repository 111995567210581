import { createSlice } from "@reduxjs/toolkit"

const leaderBoard = createSlice({
    name: 'leaderBoardSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getLeaderBoardSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addLeaderBoardSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editLeaderBoardSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})

export const { getLeaderBoardSlice, addLeaderBoardSlice, editLeaderBoardSlice } = leaderBoard.actions

export default leaderBoard.reducer 