import { all } from "redux-saga/effects"
import { watchUsersAsync } from "./userSaga"
import {watchLeaderBoardAsync } from "./LeaderBoard"

export function* rootSaga() {
    yield all([
 
        watchLeaderBoardAsync(),
        watchUsersAsync(),
        
    ])
}