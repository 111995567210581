
import * as api from '../../apis/index'
import * as LeaderBoardSlice from '../slice/LeaderBoardSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as user from '../slice/user'
import TYPES from "../types"


export function* getLeaderBoardSaga() {
    const leaderBoard = yield api.getLeaderBoardAPI()
   
      //  console.log("!!!!!!!!!!!")
        //console.log(leaderBoard)

       var newArray = []
    for(var i=0; i<leaderBoard.data.rows.length ; i++){
        if(leaderBoard.data.rows[i].userId !== undefined  && leaderBoard.data.rows[i].userId !== '' && leaderBoard.data.rows[i].userId !== '123' &&  leaderBoard.data.rows[i].userId !== '12345'){
        var useData =  yield api.getUserByIdAPI(leaderBoard.data.rows[i].userId);
        
       // console.log("^^^^^^^^^^^^%%%%%%%")
      //  console.log(useData)
       
      
       
        if(useData.walletAddress !== "Guest" &&   useData.linkENSName == undefined 
        ){
            console.log("****************")
            // console.log(useData)
            // console.log(useData.name)
            //  console.log(useData.linkENSName && useData.linkedENSName.name)
var kk
 if( useData.linkedENSName.name == undefined){
   kk= useData.name+"#"+useData.walletAddress.slice(-4)
   console.log(kk)
 }else if( useData.linkedENSName.name !== undefined){
    kk= useData.linkedENSName.name
 
 }
        var newData = {
            userID:leaderBoard.data.rows[i].userId,
            score: leaderBoard.data.rows[i].correctAnswers,
           // userName: useData.name+"#"+useData.walletAddress.slice(-4)
           //   userName: useData.linkedENSName.name+"#"+useData.walletAddress.slice(-4)
           userName: kk,
           besttime: leaderBoard.data.rows[i].bestTime
        }
        newArray.push(newData)


        }


        }

    //    console.log("+++++++++++")
    //    console.log(newArray)
    }

   
    var newArray2 = [];
    var highestBestScore = {};
  
    newArray.forEach((item) => {
      var userId = item.userID;
      var newScore = item.score;
    
      // Check if the userId already has a stored bestTime, and update it if the new bestTime is higher
      if (!highestBestScore[userId] || newScore > highestBestScore[userId]) {
        highestBestScore[userId] = newScore;
    //    console.log("000000000000000")
    //    console.log(highestBestScore)
      }
    });
    
    // Create the new array with the highest besttime for each userId
    newArray.forEach((item) => {
      var userId = item.userID;
      var newScore = item.score;
    
      // Check if the current bestTime is the highest for this userId
      if (newScore === highestBestScore[userId]) {
        newArray2.push(item);
      }
    });
    
 //   console.log("%%%%%%%%%%##########");
  //  console.log(newArray2);




   
 
var sortNewArray = newArray2.sort(function(a, b) {
 
 
    var scoreA = parseFloat(a.score);
    var scoreB = parseFloat(b.score);
    
   // console.log(scoreA)

   // console.log(scoreB)
    

    if (scoreB !== scoreA) {
          // Compare the 'score' property in descending order
        return scoreB - scoreA;
    } else {
        // If scores are equal, compare the 'time' property in ascending order
        return a.besttime - b.besttime;
    }
});

 //  console.log(sortNewArray)

    yield put(LeaderBoardSlice.getLeaderBoardSlice(sortNewArray))


    
}







export function* watchLeaderBoardAsync() {
    yield takeEvery(TYPES.GET_LEADER_BOARD,  getLeaderBoardSaga)
 


}