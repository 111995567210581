
const TYPES = {
   

GET_USERS      : 'GET_USERS',
    
GET_USER_BY_ID : 'GET_USER_BYid',
GET_USER_BY_WALLET_ADDRESS: 'GET_USER_BYwalletaddress',
CREATE_USER    : 'CREATE_USER',

UPDATE_USER_BY_ID : 'UPDATE_USER_BYid',
DELETE_USER_BY_ID : 'DELETE_USER_BYid',
GET_USER_SEARCH: 'GET_USER_SEARCH',







GET_LEADER_BOARD : "GET_LEADER_BOARD",





}
  
export default TYPES
