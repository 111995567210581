import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "@redux-saga/core"
import {rootSaga} from './redux/sagas'

import users from "./redux/slice/usersSlice"
import user from "./redux/slice/user"


import leaderBoardSlice from "./redux/slice/LeaderBoardSlice"
import leaderBoardDetail from "./redux/slice/LeaderBoardDetail"


const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {

        users,
        user,

        leaderBoardSlice,
        leaderBoardDetail 
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export default store